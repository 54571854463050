import {
  ClaimPlatform,
  completeClaimChallenge,
  createClaimChallenge,
  isWrappedErrorWithSignature,
  SignedBlockchainClaimChallenge,
} from './http'
import type { AxiosInstance } from 'axios'
import Web3 from 'web3'
import { createSimpleRegistrationError } from './errors'
import assert, { fail } from 'assert'
import { AbstractProvider, TransactionConfig } from 'web3-core'
import {
  HIGHLIGHT_MINT_MANAGER_ABI,
  ART_BLOCKS_INTEGRATION_ABI_V1,
  ART_BLOCKS_INTEGRATION_ABI_V2,
  ERC_721_ABI,
  GRANT_ROLE_ABI,
  MANIFOLD_ABI,
  MANIFOLD_EXTENSION_ABI,
  MANIFOLD_EXTENSION_V2_ABI,
  MANIFOLD_HAS_MINTING_PERMISSION_ABI,
} from './abis'
import contractSovereignABIV5 from './contractData/contractSovereign_V5.abi.json'

export enum Blockchain {
  POLYGON = 'POLYGON',
  POLYGON_AMOY = 'POLYGON_AMOY',
  ETHEREUM = 'ETHEREUM',
  ETHEREUM_SEPOLIA = 'ETHEREUM_SEPOLIA',
  FAKE_ETHEREUM_ON_SEPOLIA = 'FAKE_ETHEREUM_ON_SEPOLIA',
  FAKE_BASE_ON_BASE_SEPOLIA = 'FAKE_BASE_ON_BASE_SEPOLIA',
  BASE_MAINNET = 'BASE_MAINNET',
  BASE_SEPOLIA = 'BASE_SEPOLIA',

  // These are dead chains. They should only be used for rendering old certificates
  // {{{
  ETHEREUM_GOERLI = 'ETHEREUM_GOERLI',
  POLYGON_MUMBAI = 'POLYGON_MUMBAI',
  // }}}
}

/**
 * The overall type of chain or ecosystem, regardless of which actual chain.
 * This is needed because we now support testnets in both Polygon and Ethereum, but we treat
 * them differently (e.g. different Icons, gasless minting etc).
 */
export enum BlockchainClass {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
  BASE = 'BASE',
}

// ------------------------------------
// Gas costs
// See https://docs.google.com/spreadsheets/d/1PnxXlq0pNTmz8GWnMKl7l-s6VbdMkRD3BoHKPaRS7F8/edit#gid=0
// ------------------------------------
/**
 * Minting on the shared Verisart contract
 */
export const GAS_COST_MINT_VERISART_SHARED = 274_993

export const GAS_COST_MINT = 288_549 // Worst case scenario: v3 of the contract with royalties set
export const GAS_COST_MINT_SIGNED = 297_889 // V5 with 1 royalty recipient set
export const GAS_COST_DEPLOY = 391_733 // V5
export const GAS_COST_GRANT = 101_295
export const GAS_COST_REVOKE = 101_295
export const GAS_COST_MANIFOLD_GRANT = 91_632
export const GAS_COST_MANIFOLD_REVOKE = 91_632
export const GAS_COSTS_MANIFOLD_REGISTER_EXTENSION = 109_021 * 2.0 // The first value is what we observed experimentally. For some reason a user ran out of gas here, so we double it.
export const GAS_COST_TRANSFER = 89_451 * 2.0

// Manifold
const manifoldBase = 193_750 // Worst case scenario went minting first token. See https://verisart.atlassian.net/browse/VER-6104
const manifoldStorageEstimate = 43_000
export const GAS_COSTS_MANIFOLD = manifoldBase + manifoldStorageEstimate * 2
export const GAS_COST_MINT_SIGNED_MANIFOLD =
  manifoldBase + manifoldStorageEstimate * 2
export const GAS_COST_MINT_SIGNED_ART_BLOCKS_V1_NO_HASH = 225_277
export const GAS_COST_MINT_SIGNED_ART_BLOCKS_V2_NO_HASH = 225_277 // TODO

// The actual value is 168_520 (see tx https://sepolia.etherscan.io/tx/0x58d843e3d1f04acb4cc13adcbfa4105ec8d6e2089568edf8f384263e0f4d99b0 )
// but this seems a bit low based on the previous V1 behaviour so we're adding an extra bonus here (in addition to the standard +30% that
// will be applied later)
export const GAS_COST_MINT_SIGNED_ART_BLOCKS_V2_HASH = 168_520 * 1.2

export const GAS_COST_MINT_SIGNED_HIGHLIGHT = 250_411
export const GAS_COST_MINT_SIGNED_CHOOSE_HIGHLIGHT = 318_654

// ------------------------------------

export const SEPOLIA_NETWORK_ID = '0xaa36a7'
export const MAINNET_NETWORK_ID = '0x1'
export const POLYGON_AMOY_NETWORK_ID = '0x13882'
export const POLYGON_MAINNET_NETWORK_ID = '0x89'

export function getMainChainId(blockchain: Blockchain): string {
  // Note that on test environment the mainnets actually still point to testnet
  if (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain == Blockchain.FAKE_ETHEREUM_ON_SEPOLIA
  )
    return SEPOLIA_NETWORK_ID
  if (blockchain === Blockchain.POLYGON_AMOY) return POLYGON_AMOY_NETWORK_ID
  if (
    blockchain === Blockchain.BASE_SEPOLIA ||
    blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
  )
    return '0x14a34'
  if (blockchain === Blockchain.ETHEREUM) return MAINNET_NETWORK_ID
  if (blockchain === Blockchain.POLYGON) return POLYGON_MAINNET_NETWORK_ID
  if (blockchain === Blockchain.BASE_MAINNET) return '0x2105'
  throw Error(`Unknown blockchain ${blockchain}`)
}

export function getMainChainIdNumber(blockchain: Blockchain): number {
  return parseInt(getMainChainId(blockchain).slice(2), 16)
}

export function openSeaAssetsBaseUrl(blockchain: Blockchain): string {
  // Note that on test environment the mainnets actually still point to testnet
  if (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA
  )
    return 'https://testnets.opensea.io/assets/sepolia'
  if (blockchain === Blockchain.POLYGON_AMOY)
    return 'https://testnets.opensea.io/assets/amoy'
  if (
    blockchain === Blockchain.BASE_SEPOLIA ||
    blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
  )
    return 'https://testnets.opensea.io/assets/base-sepolia'

  if (blockchain === Blockchain.POLYGON_MUMBAI)
    return 'https://testnets.opensea.io/assets/mumbai'
  if (blockchain === Blockchain.ETHEREUM)
    return 'https://opensea.io/assets/ethereum'
  if (blockchain === Blockchain.POLYGON)
    return 'https://opensea.io/assets/matic'
  if (blockchain === Blockchain.BASE_MAINNET)
    return 'https://opensea.io/assets/base'
  if (blockchain === Blockchain.ETHEREUM_GOERLI)
    return 'https://opensea.io/assets/goerli'
  throw Error(`Unknown blockchain ${blockchain}`)
}

// See RegistrationErrorCause
export const translations = {
  no_wallet_found: 'No wallet found',
  already_linked: 'Wallet already linked',
  already_claimed_by_other:
    'This wallet is linked to a different email address',
  metamask_already_pending: 'Metamask has a pending transaction',
  metamask_user_cancelled: 'User cancelled transaction',
  unknown: 'Error occurred when linking wallet. Technical information: ',
}

export function scannerBaseUrl(blockchain: Blockchain): string {
  // Note that on test environment the mainnets actually still point to testnet
  if (
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA
  )
    return 'https://sepolia.etherscan.io'
  if (blockchain === Blockchain.POLYGON_AMOY)
    return 'https://amoy.polygonscan.com'
  if (
    blockchain === Blockchain.BASE_SEPOLIA ||
    blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA
  )
    return 'https://sepolia.basescan.org'

  if (blockchain === Blockchain.POLYGON_MUMBAI)
    return 'https://mumbai.polygonscan.com'
  if (blockchain === Blockchain.ETHEREUM) return 'https://etherscan.io'
  if (blockchain === Blockchain.POLYGON) return 'https://polygonscan.com'
  if (blockchain === Blockchain.BASE_MAINNET) return 'https://basescan.org'
  if (blockchain === Blockchain.ETHEREUM_GOERLI)
    return 'https://goerli.etherscan.io'
  throw Error(`Unknown blockchain ${blockchain}`)
}

/** See BlockchainClass */
export function blockchainToClass(blockchain: Blockchain): BlockchainClass {
  return blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.FAKE_ETHEREUM_ON_SEPOLIA ||
    blockchain === Blockchain.ETHEREUM_GOERLI ||
    blockchain === Blockchain.ETHEREUM
    ? BlockchainClass.ETHEREUM
    : blockchain === Blockchain.POLYGON_MUMBAI ||
      blockchain === Blockchain.POLYGON ||
      blockchain === Blockchain.POLYGON_AMOY
    ? BlockchainClass.POLYGON
    : blockchain === Blockchain.BASE_MAINNET ||
      blockchain === Blockchain.FAKE_BASE_ON_BASE_SEPOLIA ||
      blockchain === Blockchain.BASE_SEPOLIA
    ? BlockchainClass.BASE
    : fail('Unknown blockchain')
}

export function isTestnet(blockchain: Blockchain) {
  return (
    blockchain === Blockchain.ETHEREUM_GOERLI ||
    blockchain === Blockchain.ETHEREUM_SEPOLIA ||
    blockchain === Blockchain.POLYGON_MUMBAI ||
    blockchain === Blockchain.POLYGON_AMOY ||
    blockchain === Blockchain.BASE_SEPOLIA
  )
}

export type SimpleRegistrationErrorCause =
  | 'no_wallet_found'
  | 'already_linked'
  | 'metamask_already_pending'
  | 'metamask_user_cancelled'
  | 'magic_user_cancelled'

export interface SimpleRegistrationError extends Error {
  type: 'simple_registration_error'
  cause: SimpleRegistrationErrorCause
  isRegistrationError: boolean
}

export interface WalletLinkedOtherComplexRegistrationError extends Error {
  type: 'complex_registration_error'
  cause: 'already_claimed_by_other'
  isRegistrationError: boolean
  misc: {
    detailedMessage: string
    signedChallenge: SignedBlockchainClaimChallenge
  }
}

export function isConnectWalletError(err: unknown): err is ConnectWalletError {
  if (!err) return false
  return (
    isRegistrationError(err) || isWalletLinkedOtherComplexRegistrationError(err)
  )
}

export type ConnectWalletError =
  | SimpleRegistrationError
  | WalletLinkedOtherComplexRegistrationError

export function isWalletLinkedOtherComplexRegistrationError(
  err: unknown
): err is WalletLinkedOtherComplexRegistrationError {
  return (
    (err as any).cause === 'already_claimed_by_other' &&
    (err as any).type === 'complex_registration_error'
  )
}

export const isRegistrationError = (
  err: unknown
): err is SimpleRegistrationError => {
  return (
    ((err as any).isRegistrationError ?? false) &&
    ((err as any).type === 'simple_registration_error' ||
      (err as any).type === 'complex_registration_error')
  )
}

const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g

const already_claimed_by_other_recovery_translation = (
  ownedBy: string,
  changeTo: string
) =>
  `This wallet is already associated to an existing email "${ownedBy}". Would you like to move this wallet to the email address "${changeTo}"?`
/**
 * Links/registers a wallet with the user's account on core
 *
 * Throws exceptions in the form defined by
 *
 * @see createRegistrationError
 */
export async function registerWallet(
  webClient: AxiosInstance,
  web3: Web3,
  account: string,
  moveExisting: boolean,
  claimPlatform: ClaimPlatform,
  shopDomain: string | null
): Promise<void> {
  try {
    const claimChallenge = await createClaimChallenge(
      webClient,
      account,
      claimPlatform,
      shopDomain
    )
    if (claimChallenge) {
      const { challenge, accountCommitment } = claimChallenge
      const signature = await web3.eth.personal.sign(challenge, account, '')
      await completeClaimChallenge(webClient, {
        accountCommitment,
        address: account,
        challenge,
        signature,
        moveExisting,
      })
    } else {
      throw createSimpleRegistrationError('already_linked')
    }
  } catch (error: any) {
    const { e, signedChallenge } = isWrappedErrorWithSignature(error)
      ? { e: error.wrappedError, signedChallenge: error.signedChallenge }
      : { e: error, signedChallenge: undefined }
    if (!isRegistrationError(e)) {
      if (e.message?.includes('already pending')) {
        throw createSimpleRegistrationError('metamask_already_pending')
      }
      if (
        e.message?.includes('User denied') ||
        e.message?.includes('User cancelled')
      ) {
        throw createSimpleRegistrationError('metamask_user_cancelled')
      }

      if (
        e.response?.data?.message?.includes('already.claimed.other') ||
        e.data?.message?.includes('already.claimed.other')
      ) {
        assert(signedChallenge, 'signedChallenge should be defined here')
        const receivedErrorMessage =
          e.response?.data?.message ?? e.data?.message
        let errorMsg: string =
          translations['already_claimed_by_other' as keyof typeof translations]
        if (receivedErrorMessage) {
          const emailAddresses = receivedErrorMessage.match(emailPattern)
          if (emailAddresses && emailAddresses.length === 2) {
            errorMsg = already_claimed_by_other_recovery_translation(
              emailAddresses?.[1],
              emailAddresses?.[0]
            )
          }
        } else {
          console.error(
            "Expected to receive error message from server but didn't"
          )
          throw e
        }
        // For reasons, website wraps axios errors with the response object so we need to look there too
        const error: WalletLinkedOtherComplexRegistrationError = {
          name: 'RegistrationError',
          message: translations['already_claimed_by_other'],
          type: 'complex_registration_error',
          cause: 'already_claimed_by_other',
          isRegistrationError: true,
          misc: {
            detailedMessage: errorMsg,
            signedChallenge,
          },
        }
        throw error
      }

      if (e.error?.code === 4001) {
        if (e.error?.message === 'A request is already in progress') {
          throw createSimpleRegistrationError('metamask_already_pending')
        }
        throw createSimpleRegistrationError('metamask_user_cancelled')
      }
      throw e
    }
    throw e
  }
}

/**
 * Legacy way of getting the current user in Metamask - only used in some special hacky pages. Do not use this.
 */
export async function getUserAddress(): Promise<string> {
  await Web3.givenProvider.enable()

  const userAddresses = await Web3.givenProvider.request({
    method: 'eth_requestAccounts',
  })
  const userAddress = userAddresses[0]

  if (!userAddress) {
    throw new Error('No active account found')
  }
  return userAddress
}

/**
 * Deploys a contract and returns a promise for the *transaction accepted* (rather than the transaction complete)
 */
export async function deployContract(
  web3: Web3,
  userAddress: string,
  abi: any,
  bytecode: string,
  args: any[],
  gas: number
): Promise<string> {
  let onStarted: (transactionHash: string) => void
  let onError: (e: Error) => void
  const promise = new Promise<string>((resolve, error) => {
    onStarted = resolve
    onError = error
  })

  const contract = await new web3.eth.Contract(abi as any, undefined, {
    data: bytecode,
  })

  contract
    .deploy({
      data: bytecode,
      arguments: args,
    })
    .send({
      from: userAddress, // We are minting from the users address
      gas: Math.floor(gas * 1.3), // fee estimate to complete the transaction

      // See https://stackoverflow.com/a/68936204
      // {{{
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // }}}
    } as any) // The TS bindings do not allow null for `maxPriorityFeePerGas` despite it actually working - see above
    .on('transactionHash', (hash) => {
      onStarted(hash)
    })
    .on('error', (e: Error) => {
      onError(e)
    })

  return promise
}

async function createTx(
  web3: Web3,
  abi: any,
  contractAddress: string,
  applyMethods: (methods: any) => any,
  userAddress: string,
  gas: number,
  payableGwei?: number
): Promise<TransactionConfig> {
  const contract = await new web3.eth.Contract(abi, contractAddress)

  const encodedABI = applyMethods(contract.methods).encodeABI()
  const valueInWei =
    payableGwei !== undefined
      ? web3.utils.toWei(payableGwei.toString(), 'gwei')
      : undefined

  return {
    from: userAddress,
    to: contractAddress,
    // See https://stackoverflow.com/a/68936204
    // {{{
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // }}}
    gas: Math.floor(gas * 1.3),
    data: encodedABI,
    value: valueInWei, // Note this needs to be undefined and not null or else ledger wallets break - see VER-7608
  } as any // Using `any` because the TS bindings do not allow null for `maxPriorityFeePerGas` and `maxFeePerGas` despite it actually working - see above
}

/**
 * Send a transaction and return a promise for when the transaction has been accepted (but not necessarily complete)
 *
 * @param web3
 * @param userAddress
 * @param contractAddress
 * @param abi
 * @param gas The expected gas for this transaction. This will be increased by 1.3 to give some headroom
 * @param applyMethods Gets invoked with the value of `contract.methods`. Use this to invoke the function you are
 *                     calling and return the result. e.g: `(methods) => methods.myContractCall("hello")`
 */
export async function sendTransaction(
  web3: Web3,
  userAddress: string,
  contractAddress: string,
  abi: any,
  gas: number,
  applyMethods: (methods: any) => any,
  payableGwei?: number
): Promise<string> {
  let onStarted: (transactionHash: string) => void
  let onError: (e: Error) => void
  const promise = new Promise<string>((resolve, error) => {
    onStarted = resolve
    onError = error
  })

  const tx = await createTx(
    web3,
    abi,
    contractAddress,
    applyMethods,
    userAddress,
    gas,
    payableGwei
  )

  web3.eth
    .sendTransaction(tx)
    .on('transactionHash', (hash) => {
      onStarted(hash)
    })
    .on('error', (e) => {
      onError(e)
    })

  return promise
}

/**
 * Send a transaction and return a promise for when the transaction has been completed
 * @param web3
 * @param userAddress
 * @param contractAddress
 * @param abi
 * @param gas The expected gas for this transaction. This will be increased by 1.3 to give some headroom
 * @param applyMethods Gets invoked with the value of `contract.methods`. Use this to invoke the function you are
 *                     calling and return the result. e.g: `(methods) => methods.myContractCall("hello")`
 */
export async function sendTransactionAndWaitUntilComplete(
  web3: Web3,
  userAddress: string,
  contractAddress: string,
  abi: any,
  gas: number,
  applyMethods: (methods: any) => any,
  onStarted: ((transactionHash: string) => void) | null = null
): Promise<string> {
  const tx = await createTx(
    web3,
    abi,
    contractAddress,
    applyMethods,
    userAddress,
    gas
  )
  const result = await web3.eth
    .sendTransaction(tx)
    .on('transactionHash', (hash) => {
      onStarted?.(hash)
    })
  return result.transactionHash
}

/**
 * Value of keccak256("MINTER_ROLE")
 */
const MINTER_ROLE =
  '0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6'

/**
 * If the user can grant or revoke minting permission on a Verisart style contract
 * (using OpenZeppelin access control). This works for all versions of Verisart contracts as the
 * ABI has not changed for those functions.
 *
 * @param web3
 * @param userAddress
 * @param contractAddress
 */
export async function canGrantOrRevokeMintingPermission(
  web3: Web3,
  userAddress: string,
  contractAddress: string
): Promise<boolean> {
  const contract = await new web3.eth.Contract(
    GRANT_ROLE_ABI as any,
    contractAddress
  )
  return await contract.methods
    .hasRole(
      '0x0000000000000000000000000000000000000000000000000000000000000000',
      userAddress
    )
    .call()
}

export async function ownerOf721(
  web3: Web3,
  contractAddress: string,
  tokenId: string
): Promise<string> {
  const contract = await new web3.eth.Contract(
    ERC_721_ABI as any,
    contractAddress
  )
  try {
    const x = await contract.methods.ownerOf(tokenId).call()
    return x
  } catch (e) {
    const error: any = new Error(
      `Failed to get owner of token ${tokenId} on contract ${contractAddress}`
    )
    error.cause = e
    throw error
  }
}

export async function getApproved(
  web3: Web3,
  contractAddress: string,
  tokenId: string
): Promise<string> {
  const contract = await new web3.eth.Contract(
    ERC_721_ABI as any,
    contractAddress
  )
  try {
    const x = await contract.methods.getApproved(tokenId).call()
    return x
  } catch (e) {
    const error: any = new Error(
      `Failed to retrieve approved address for token ${tokenId} on contract ${contractAddress}.`
    )
    error.cause = e
    throw error
  }
}

export async function isApprovedForAll(
  web3: Web3,
  contractAddress: string,
  ownerAddress: string,
  operatorAddress: string
): Promise<boolean> {
  const contract = await new web3.eth.Contract(
    ERC_721_ABI as any,
    contractAddress
  )
  try {
    const x = await contract.methods
      .isApprovedForAll(ownerAddress, operatorAddress)
      .call()
    return x
  } catch (e) {
    const error: any = new Error(
      `Failed to get approval for all for owner ${ownerAddress} on contract ${contractAddress}`
    )
    error.cause = e
    throw error
  }
}

export async function safeTransferFrom721(
  web3: Web3,
  contractAddress: string,
  tokenId: string,
  fromAddress: string,
  toAddress: string,
  onStarted: ((transactionHash: string) => void) | null = null
): Promise<string> {
  return sendTransactionAndWaitUntilComplete(
    web3,
    fromAddress,
    contractAddress,
    ERC_721_ABI,
    GAS_COST_TRANSFER,
    (methods) => methods.safeTransferFrom(fromAddress, toAddress, tokenId),
    onStarted
  )
}

export async function grantMintingPermission(
  web3: Web3,
  userAddress: string,
  contractAddress: string,
  grantPermissionTo: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    contractAddress,
    GRANT_ROLE_ABI,
    GAS_COST_GRANT,
    (methods) => methods.grantRole(MINTER_ROLE, grantPermissionTo)
  )
}

export async function revokeMintingPermission(
  web3: Web3,
  userAddress: string,
  contractAddress: string,
  revokePermissionFrom: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    contractAddress,
    GRANT_ROLE_ABI,
    GAS_COST_REVOKE,
    (methods) => methods.revokeRole(MINTER_ROLE, revokePermissionFrom)
  )
}

export async function manifoldExtensionGrantMintingPermission(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  manifoldContractAddress: string,
  grantPermissionTo: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    extensionAddress,
    MANIFOLD_EXTENSION_ABI,
    GAS_COST_MANIFOLD_GRANT,
    (methods) =>
      methods.grantMinting(manifoldContractAddress, grantPermissionTo)
  )
}

export async function manifoldExtensionRevokeMintingPermission(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  manifoldContractAddress: string,
  revokePermissionFrom: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    extensionAddress,
    MANIFOLD_EXTENSION_ABI,
    GAS_COST_MANIFOLD_REVOKE,
    (methods) =>
      methods.revokeMinting(manifoldContractAddress, revokePermissionFrom)
  )
}

export async function mintHighlightChooseSignableTransaction(
  web3: Web3,
  userAddress: string,
  mintManagerAddress: string,
  mintTo: string,
  mechanicVectorId: string,
  highlightChosenTokens: string[],
  signature: string,
  highlightMintFee: number
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    mintManagerAddress,
    HIGHLIGHT_MINT_MANAGER_ABI,
    GAS_COST_MINT_SIGNED_CHOOSE_HIGHLIGHT * highlightChosenTokens.length,
    (method) =>
      method.mechanicMintChoose(
        mechanicVectorId,
        mintTo,
        highlightChosenTokens,
        signature
      ),
    highlightMintFee * highlightChosenTokens.length
  )
}

export async function mintHighlightSignableTransaction(
  web3: Web3,
  userAddress: string,
  mintManagerAddress: string,
  mintTo: string,
  mechanicVectorId: string,
  numToMint: number,
  signature: string,
  highlightMintFee: number
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    mintManagerAddress,
    HIGHLIGHT_MINT_MANAGER_ABI,
    GAS_COST_MINT_SIGNED_HIGHLIGHT * numToMint,
    (method) =>
      method.mechanicMintNum(mechanicVectorId, mintTo, numToMint, signature),
    highlightMintFee * numToMint
  )
}

export async function mintArtblocksSignableTransaction(
  web3: Web3,
  userAddress: string,
  mintTo: string,
  projectId: number,
  coreContractAddress: string,
  minterContractAddress: string,
  tokenNonce: string,
  signature: string,
  artBlocksHash: string | null,
  version: '1.0' | '2.0'
): Promise<string> {
  if (version == '1.0') {
    assert(!artBlocksHash, 'artBlocksHash is only supported in version 2.0')
    return sendTransaction(
      web3,
      userAddress,
      minterContractAddress,
      ART_BLOCKS_INTEGRATION_ABI_V1,
      GAS_COST_MINT_SIGNED_ART_BLOCKS_V1_NO_HASH,
      (method) =>
        method.mintSigned(
          mintTo,
          projectId,
          coreContractAddress,
          tokenNonce,
          signature
        )
    )
  } else {
    if (!artBlocksHash) {
      return sendTransaction(
        web3,
        userAddress,
        minterContractAddress,
        ART_BLOCKS_INTEGRATION_ABI_V2,
        GAS_COST_MINT_SIGNED_ART_BLOCKS_V2_NO_HASH,
        (method) =>
          // TODO: Check method name
          method.mintSigned(
            mintTo,
            projectId,
            coreContractAddress,
            tokenNonce,
            signature
          )
      )
    } else {
      assert(
        tokenNonce === artBlocksHash,
        `Token nonce must be the hash of the art blocks hash. Got ${tokenNonce} but expected ${artBlocksHash}`
      )
      return sendTransaction(
        web3,
        userAddress,
        minterContractAddress,
        ART_BLOCKS_INTEGRATION_ABI_V2,
        GAS_COST_MINT_SIGNED_ART_BLOCKS_V2_HASH,
        (method) =>
          // TODO: Check method name
          method.mintSignedHash(
            mintTo,
            projectId,
            coreContractAddress,
            artBlocksHash,
            signature
          )
      )
    }
  }
}

export async function mintManifoldSignableTransaction(
  web3: Web3,
  creatorContractAddress: string,
  extensionAddress: string,
  mintTo: string,
  uri: string,
  tokenNonce: string,
  userAddress: string,
  royaltyAddresses: string[],
  royaltyBpss: number[],
  signature: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    extensionAddress,
    MANIFOLD_EXTENSION_V2_ABI,
    GAS_COST_MINT_SIGNED_MANIFOLD,
    (methods) =>
      methods.mintSigned(
        mintTo,
        creatorContractAddress,
        uri,
        tokenNonce,
        royaltyAddresses,
        royaltyBpss,
        signature
      )
  )
}

export async function mintSignableTransaction(
  web3: Web3,
  contractAddress: string,
  mintTo: string,
  uri: string,
  tokenNonce: string,
  userAddress: string,
  royaltyAddresses: string[],
  royaltyBpss: number[],
  signature: string
): Promise<string> {
  return sendTransaction(
    web3,
    userAddress,
    contractAddress,
    contractSovereignABIV5,
    GAS_COST_MINT_SIGNED,
    (methods) =>
      methods.mintSigned(
        mintTo,
        uri,
        tokenNonce,
        royaltyAddresses,
        royaltyBpss,
        signature
      )
  )
}

export async function manifoldExtensionHasMintingPermission(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  manifoldContractAddress: string
): Promise<boolean> {
  const contract = await new web3.eth.Contract(
    MANIFOLD_HAS_MINTING_PERMISSION_ABI as any,
    extensionAddress
  )
  return await contract.methods
    .hasMintingPermission(manifoldContractAddress, userAddress)
    .call()
}

export async function manifoldIsAdmin(
  web3: Web3,
  userAddress: string,
  contractAddress: string
): Promise<boolean> {
  const contract = await new web3.eth.Contract(
    MANIFOLD_ABI as any,
    contractAddress
  )
  return await contract.methods.isAdmin(userAddress).call()
}

export async function manifoldGetExtensions(
  web3: Web3,
  userAddress: string,
  contractAddress: string
): Promise<string[]> {
  const contract = await new web3.eth.Contract(
    MANIFOLD_ABI as any,
    contractAddress
  )
  return await contract.methods.getExtensions().call()
}

export async function manifoldRegisterExtensionAndWaitForCompletion(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  baseUri: string,
  contractAddress: string
): Promise<string> {
  return sendTransactionAndWaitUntilComplete(
    web3,
    userAddress,
    contractAddress,
    MANIFOLD_ABI,
    GAS_COSTS_MANIFOLD_REGISTER_EXTENSION,
    (methods) => methods.registerExtension(extensionAddress, baseUri)
  )
}
interface Currency {
  name: string
  symbol: string
  decimals: number
}

interface Explorer {
  name: string
  url: string
  standard: string
}

export interface Chain {
  name: string
  chain: string
  network: string
  rpc: string[]
  faucets: string[]
  nativeCurrency: Currency
  infoURL: string
  shortName: string
  chainId: number
  networkId: number
  slip44: number
  explorers: Explorer[]
}

export const fetchChainInfo = async (chainId: number) => {
  try {
    const response = await fetch('https://chainid.network/chains.json')
    const chains: Chain[] = await response.json()

    return chains.find((chain) => chain.chainId === chainId) ?? null
  } catch (e) {
    console.error('chain information error:', e)
  }
}

export async function manifoldIsExtensionRegistered(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  contractAddress: string
): Promise<boolean> {
  const extensions = (
    await manifoldGetExtensions(web3, userAddress, contractAddress)
  ).map((it) => it.toLowerCase())
  return extensions.includes(extensionAddress.toLowerCase())
}

export async function manifoldCanMintOnExtension(
  web3: Web3,
  userAddress: string,
  extensionAddress: string,
  contractAddress: string
) {
  return (
    (await manifoldExtensionHasMintingPermission(
      web3,
      userAddress,
      extensionAddress,
      contractAddress
    )) &&
    (await manifoldIsExtensionRegistered(
      web3,
      userAddress,
      extensionAddress,
      contractAddress
    ))
  )
}

export const ErrorMetamaskUnknownChain = 4902

export const sleep = <T>(
  delay: number,
  resolveValue?: T
): Promise<T | undefined> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(resolveValue), delay)
  })

export async function getChainId(web3: Web3): Promise<string> {
  const provider = web3.currentProvider as AbstractProvider
  assert(provider.request, `Wrong value for currentProvider. Got ${provider}`)
  const val = await provider.request({
    method: 'eth_chainId',
  })

  // eslint-disable-next-line no-console
  console.info('Current chain is', val)

  if (typeof val === 'number') {
    return `0x${val.toString(16)}`
  } else {
    return val
  }
}

export type ContractType =
  | 'REGULAR'
  | 'DROP'
  | 'RESERVED_TOKEN_DROP'
  | 'MANIFOLD_EXTENSION'
  | 'MANIFOLD'
  | 'ART_BLOCKS'
  | 'HIGHLIGHT'
