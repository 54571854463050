import React from 'react'

type TextVariant =
  | 'large'
  | 'large-2xl'
  | 'large-3.5xl'
  | 'small'
  | 'base'
  | string

interface TextProps {
  as?: 'p' | 'span'
  variant?: TextVariant
  bold?: boolean
}

const textClassName = (variant: TextVariant | undefined, bold: boolean) => {
  let className: string[]

  switch (variant) {
    case 'base':
      className = ['ver-text-base']
      break
    case 'large':
      className = ['ver-text-lg']
      break
    case 'large-2xl':
      className = ['ver-text-2xl']
      break
    case 'large-3.5xl':
      className = ['ver-text-3.5xl ver-leading-10 ']
      break
    case 'small':
      className = ['ver-text-sm']
      break
    default:
      className = variant ? [] : ['ver-text-base']
      break
  }

  if (bold) {
    className = ['ver-font-bold']
  }

  return className.join(' ')
}

const Text: React.FC<TextProps> = ({ bold, children, variant, ...rest }) => {
  const className = textClassName(variant, bold ?? false)
    ? textClassName(variant, bold ?? false)
    : variant

  return React.createElement(
    rest.as || 'span',
    {
      className: className,
    },
    children
  )
}

export default Text
