import { Spinner } from '@verisart/shared'

type ButtonProps = {
  loading?: boolean
  fullWidth?: boolean
  borderless?: boolean
  testName?: string
  secondary?: boolean
  disabledColour?: boolean
  customClassName?: string
} & Pick<
  // Do NOT add `className`! // If you need extra native props exposed, add them here on a case by case basis.
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'onClick' | 'disabled' | 'type'
>

// Do not add className to this - follow the props pattern
const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  loading,
  fullWidth,
  disabled,
  testName,
  secondary,
  disabledColour,
  customClassName,
}) => {
  let className =
    'ver-rounded-default ver-h-11 ver-border-1 ver-py-4 ver-flex ver-items-center ver-justify-center '

  if (disabled) {
    className =
      className + 'ver-text-disabled ver-border-disabled ' + disabledColour
        ? ''
        : 'ver-bg-disabledButton'
  } else if (secondary) {
    className = className + 'ver-bg-tertiary ver-text-textSecondary '
  } else {
    className =
      className + 'ver-bg-primary ver-text-surface ver-border-bg-primary '
  }

  if (fullWidth) {
    className = className + 'ver-w-full ver-font-bold '
  } else {
    className = className + 'ver-px-24 ver-whitespace-nowrap '
  }

  className = customClassName ? className + customClassName : className

  return (
    <button
      data-test={testName}
      disabled={disabled || loading}
      onClick={onClick}
      className={className}
    >
      {loading ? <Spinner /> : children}
    </button>
  )
}

export default Button
