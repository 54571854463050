import React, { useMemo } from 'react'
import { CardDetails } from '../../atoms/CardDetails'
import { ReactComponent as EthereumIcon } from './ico-ethereum.svg'
import { ReactComponent as PolygonIcon } from './ico-polygon.svg'
import { ReactComponent as BaseIcon } from './ico-base.svg'
import styles from './Dashboard.module.css'
import Button from '../../atoms/Button'
import CardTag from '../../atoms/CardTag'
import Heading from '../../atoms/Heading'
import Stack from '../../atoms/Stack'
import t, { formatString } from '../../../lib/translation'
import EmptyState from '../../molecules/EmptyState'
import { MintStatus, NFTTransferStatus } from '../../../api'
import Text from '../../atoms/Text'
import { Spinner } from '@verisart/shared'
import {
  Blockchain,
  BlockchainClass,
  blockchainToClass,
} from '@verisart/nft/src'
import { PreviewCard } from '../../molecules/PreviewCard'
import Pagination from '../Pagination'
import { ProductionYears } from '@verisart/shared'
import TopBar from '../TopBar'

export interface DashboardData {
  certificateId: string
  image: string
  title: string
  href?: string
  artistName: string
  productionYears: ProductionYears | null
  transferStatus?: NFTTransferStatus
  blockchain: Blockchain | null | undefined
  quantity: number | null
  dropVolume: number | null
  superLazyMintOrderId: string
  mintStatus: MintStatus | null
  nftTestnet: boolean
  placeholder: boolean
  mintingStartedAt: string | null
}

interface DashboardProps {
  data: DashboardData[]
  totalCount: number
  page: number
  setPage: (page: number) => void
  pageSize: number
  onClaim: (id: string, mint: boolean) => void
  showUpsellBanner: boolean
}

export interface NFTPreviewCardProps {
  certificateId: string
  image: string
  title: string
  href?: string
  artistName: string
  productionYears: ProductionYears | null
  transferStatus?: NFTTransferStatus
  blockchain: Blockchain | null | undefined
  onClaim?: (id: string, mint: boolean) => void
  quantity: number | null
  superLazyMintOrderId: string
  mintStatus: MintStatus | null
  nftTestnet: boolean
  placeholder: boolean
  mintingStartedAt: string | null
}

function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000)
}

const MINUTES_CONSIDERED_TAKING_TOO_LONG = 15

const NFTPreviewCard: React.VFC<NFTPreviewCardProps> = ({
  artistName,
  blockchain,
  href,
  certificateId,
  image,
  productionYears,
  transferStatus,
  title,
  onClaim,
  quantity,
  superLazyMintOrderId,
  mintStatus,
  nftTestnet,
  placeholder,
  mintingStartedAt,
}) => {
  let imageBadge: React.ReactElement | undefined

  if (blockchain) {
    if (blockchainToClass(blockchain) === BlockchainClass.ETHEREUM) {
      imageBadge = <EthereumIcon />
    } else if (blockchainToClass(blockchain) === BlockchainClass.POLYGON) {
      imageBadge = <PolygonIcon />
    } else if (blockchainToClass(blockchain) === BlockchainClass.BASE) {
      imageBadge = <BaseIcon />
    }
  }

  const subtitle = useMemo(
    () =>
      quantity
        ? formatString(
            quantity === 1
              ? t.dashboard.subtitleOneNft
              : t.dashboard.subtitleManyNfts,
            quantity
          )
        : undefined,
    [quantity]
  )

  const mintingTakingTooLong: boolean =
    (transferStatus === 'PENDING' || mintStatus === 'MINTING') &&
    mintingStartedAt != null &&
    addMinutes(new Date(mintingStartedAt), MINUTES_CONSIDERED_TAKING_TOO_LONG) <
      new Date()

  return (
    <PreviewCard
      href={href}
      title={title}
      image={image}
      imageBadge={imageBadge}
      overlayMessage={
        placeholder ? t.dashboard.cards.placeholderMintOverlayText : null
      }
      overlay={
        mintingTakingTooLong
          ? {
              title: t.dashboard.mintTakingTooLong.title,
              message: t.dashboard.mintTakingTooLong.message,
            }
          : undefined
      }
      nftTestnet={nftTestnet}
    >
      <CardDetails
        artistName={artistName}
        title={title}
        subtitle={subtitle}
        productionYears={productionYears}
        showCardStack={(quantity ?? 0) > 1}
        footer={
          <>
            {mintingTakingTooLong && (
              <div className="ver-flex ver-mt-auto">
                <CardTag status={'pending'}>
                  <Text>{t.dashboard.pendingTag}</Text>
                </CardTag>
              </div>
            )}
            {(transferStatus === 'PENDING' || mintStatus === 'MINTING') &&
            !mintingTakingTooLong ? (
              <div className="ver-flex ver-mt-auto">
                <Button fullWidth disabled>
                  <div className="ver-flex ver-justify-center ver-space-x-2">
                    <Spinner />
                    <Text>
                      {transferStatus === 'PENDING'
                        ? t.dashboard.claimInProgress
                        : t.dashboard.mintInProgress}
                    </Text>
                  </div>
                </Button>
              </div>
            ) : null}
            {transferStatus === 'COMPLETE' ||
            transferStatus === 'FAILED' ||
            mintStatus === 'COMPLETE' ? (
              <div className="ver-flex ver-mt-auto">
                <Stack>
                  <div className="ver-inline-flex">
                    <CardTag
                      status={
                        transferStatus === 'COMPLETE' ? 'success' : 'pending'
                      }
                    >
                      {t.dashboard.claimedTag}
                    </CardTag>
                  </div>
                </Stack>
              </div>
            ) : undefined}
            {(certificateId || superLazyMintOrderId) &&
            (transferStatus === 'NOT_REQUESTED' ||
              mintStatus === 'RESERVED') ? (
              <Stack className="ver-flex ver-mt-auto">
                <Button
                  fullWidth
                  onClick={(event) => {
                    event.stopPropagation()
                    certificateId
                      ? onClaim?.(certificateId, false)
                      : onClaim?.(superLazyMintOrderId, true)
                  }}
                >
                  {t.dashboard.claimButton}
                </Button>
                {placeholder && (
                  <Text variant="small">
                    <p className="ver-text-secondary-variant ver-mt-2">
                      {t.dashboard.cards.placeholderMintExtraText}
                    </p>
                  </Text>
                )}
              </Stack>
            ) : undefined}
          </>
        }
      />
    </PreviewCard>
  )
}

const Dashboard: React.FC<DashboardProps> = ({
  data,
  onClaim,
  totalCount,
  page,
  setPage,
  pageSize,
  showUpsellBanner,
}) => {
  return (
    <div className="ver-mx-20 ver-flex ver-flex-col ver-my-5">
      {showUpsellBanner && (
        <div className="ver-flex ver-bg-primary ver-justify-center ver-p-2 ver-mt-2 ver-mb-3 ver-text-lg ver-text-surface ver-text-white ver-opacity-80">
          {t.dashboard.upsellBanner}
        </div>
      )}
      <TopBar showBackButton={true} />
      <div className="ver-flex ver-justify-between">
        <div className="ver-space-y-5 ver-mb-8">
          <Heading variant="heading-1">{t.dashboard.title}</Heading>
          <Heading variant="heading-3">
            {formatString(
              totalCount === 1
                ? t.dashboard.subtitle.one
                : t.dashboard.subtitle.other,
              totalCount
            )}
          </Heading>
        </div>
      </div>

      <div
        className={`ver-w-full ver-flex ver-justify-center ver-items-center ver-mt-5 ${styles['DashboardWrapper']}`}
      >
        {data.length > 0 ? (
          <>
            <div className="ver-w-full md:ver-grid-cols-3 ver-grid ver-grid-cols-1 xl:ver-grid-cols-4 ver-gap-6">
              {data.map((card) => (
                <NFTPreviewCard
                  key={card.certificateId || card.superLazyMintOrderId}
                  onClaim={onClaim}
                  {...card}
                />
              ))}
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </div>
      {data.length > 0 && (
        <div className="ver-flex ver-justify-center ver-items-center ver-mt-5">
          <Pagination
            page={page}
            setPage={setPage}
            itemCount={totalCount}
            pageSize={pageSize}
          />
        </div>
      )}
    </div>
  )
}

export default Dashboard
