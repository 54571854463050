type HeadingVariant = 'heading-1' | 'heading-2' | 'heading-3' | string

interface HeadingProps {
  variant: HeadingVariant
  default?: HeadingVariant
}

const headingClassName = {
  'heading-1': 'ver-text-2xl ver-font-bold',
  'heading-2': 'ver-text-base ver-font-bold',
  'heading-3': 'ver-text-base ver-font-medium',
}

const Heading: React.FC<HeadingProps> = ({
  children,
  variant,
  default: defaultVariant = variant,
}) => {
  const className = headingClassName[variant]
    ? headingClassName[variant]
    : variant

  switch (
    headingClassName[variant]
      ? headingClassName[variant]
      : headingClassName[defaultVariant]
  ) {
    case headingClassName['heading-1']:
      return <h1 className={className}>{children}</h1>
    case headingClassName['heading-2']:
      return <h2 className={className}>{children}</h2>
    case headingClassName['heading-3']:
      return <h3 className={className}>{children}</h3>
    default:
      return null
  }
}

export default Heading
