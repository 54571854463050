import * as API from '../../api'
import { useCallback, useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { NFT } from '@verisart/shared'

interface ErrorData {
  message?: string
}

const isErrorData = (data: unknown): data is ErrorData => {
  return typeof data === 'object'
}

/**
 * Note this is a super old API for "custodial NFTs" - things we ony had for a short period of time.
 * See https://www.notion.so/verisart/Shopify-Custodial-NFT-feature-should-be-retired-da038363d6894fd1a0ed931c617e2dd3
 *
 * Update:
 * This is now resurrected for Artblocks
 */
const useClaim = (
  nft: NFT | undefined | null,
  withdrawTo: string | undefined
) => {
  const blockchain = nft?.blockchain
  const contractAddress = nft?.contractAddress
  const tokenId = nft?.tokenId
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>()
  const [claimed, setClaimed] = useState<boolean>()

  useEffect(() => {
    setLoading(true)
    setLoading(false)
  }, [contractAddress, tokenId])

  const claim = useCallback(async () => {
    setLoading(true)
    setError(undefined)
    try {
      if (!!blockchain && !!contractAddress && !!tokenId && !!withdrawTo) {
        await API.customer.claimNft(
          blockchain,
          contractAddress,
          tokenId,
          withdrawTo
        )
        setClaimed(true)
      }
    } catch (e) {
      const axiosError = e as AxiosError
      if (
        axiosError?.response?.status === 400 &&
        isErrorData(axiosError?.response?.data)
      ) {
        setError(axiosError.response.data.message)
      }

      // Supress 500 errors from the server and pretend things were successful
      if (axios.isAxiosError(e) && e.response !== undefined) {
        setClaimed(true)
      } else {
        setError('unknown_error')
      }
    } finally {
      setLoading(false)
    }
  }, [blockchain, contractAddress, tokenId, withdrawTo])

  return { claim, claimed, error, loading }
}

export default useClaim
