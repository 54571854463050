import { useParams } from 'react-router-dom'
import * as API from '../api'
import styles from '../styles/containers.module.css'
import { Spinner } from '@verisart/shared'
import Claim, { AssetDetails } from '../components/organisms/Claim'
import useGetCertificate from '../lib/hooks/useGetCertificate'
import useClaim from '../lib/hooks/useClaim'
import {
  ConnectorNames,
  isConnectWalletError,
  useConnectWallet,
} from '@verisart/nft/src'
import t from '../lib/translation'
import { ComputedCertificateFeature } from '@verisart/shared'
import TopBar from 'src/components/organisms/TopBar'
import ConnectWalletDialog, {
  useConnectWalletReducer,
} from '../components/organisms/ConnectWalletDialog'
import { useEffect, useState } from 'react'

/**
 * Claiming "Custodial NFTs": nfts minting immediately at
 * purchase time and held in a custodial wallet. See comment in core under
 * `CustodialNFTService` for more details.
 */
const ClaimCustodialNFT = () => {
  const { id } = useParams<'id'>()

  const {
    certificate,
    isLoading: isCertificateLoading,
    nft,
    withdrawalState,
  } = useGetCertificate(id as string)
  const assetDetails: AssetDetails | undefined = certificate
    ? {
        ...certificate,
        quantity: null,
        nftTestnet: certificate?.computedFeatures.includes(
          ComputedCertificateFeature.NFT_TESTNET
        ),
        signedMinting: false,
        claimed: false,
        tokenIds: certificate?.public.nft?.tokenId
          ? [certificate.public.nft.tokenId]
          : [],
        firstCertificatePrimaryAssetUrl: certificate?.files[0]?.url,
        completed: true,
        blockchain: certificate?.public.nft?.blockchain,
        contractAddress: certificate?.public.nft?.contractAddress,
      }
    : undefined

  const api = API.customer.axios

  const { disconnect, account: activeWallet, platform } = useConnectWallet(api)

  const {
    claim,
    claimed: claimedAfterCall,
    error: claimError,
    loading: claiming,
  } = useClaim(nft, activeWallet)

  const claimed = withdrawalState == 'COMPLETE' || claimedAfterCall

  const [state, dispatch] = useConnectWalletReducer()

  const connecting = state.working

  const [showTopBar, setShowTopBar] = useState(true)

  useEffect(() => {
    if (t.claim.dashboard.showTopBar === 'false') {
      setShowTopBar(false)
    }
  }, [])

  return (
    <div
      className={`ver-w-full ver-flex ver-flex-col ver-justify-center ver-mt-20 ${
        showTopBar ? '' : 'tablet:ver-mt-50'
      } ver-mb-20 ${styles.container}`}
    >
      {showTopBar && <TopBar blockchain={assetDetails?.blockchain} />}

      <div className="ver-flex ver-flex-grow ver-items-center ver-justify-center ver-px-4">
        {isCertificateLoading || !certificate ? (
          <Spinner />
        ) : (
          <Claim
            activeWallet={activeWallet}
            assetDetails={assetDetails}
            claimed={claimed}
            loading={connecting || claiming}
            connectError={
              state.name === 'Failure'
                ? isConnectWalletError(state.connectError)
                  ? state.connectError?.message
                  : state.connectError
                : undefined
            }
            claimError={claimError}
            onBack={() => {
              window.open(
                t.claim.button.redirectLink
                  .replaceAll(
                    '$contractAddress',
                    assetDetails?.contractAddress || ''
                  )
                  .replaceAll('$tokenId', assetDetails?.tokenIds?.[0] ?? '')
                  .replaceAll('$blockchain', assetDetails?.blockchain || ''),
                '_blank'
              )
            }}
            onConnect={() => dispatch({ type: 'ChoosePlatform' })}
            onConnectMagic={() =>
              dispatch({
                type: 'Connect',
                platform: ConnectorNames.MagicLink,
              })
            }
            onClaim={claim}
            platform={platform}
            isCustodial={true}
            showTopBar={showTopBar}
            disconnect={disconnect}
          />
        )}
      </div>
      <ConnectWalletDialog state={state} dispatch={dispatch} />
    </div>
  )
}

export default ClaimCustodialNFT
