import * as React from 'react'
import { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.27-10.124-.857-.857-4.458 4.458L5.076 7.6l-.857.857 2.31 2.31.426.408.426-.408 4.89-4.89Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.27-10.124-.857-.857-4.458 4.458L5.076 7.6l-.857.857 2.31 2.31.426.408.426-.408 4.89-4.89Z"
      fill="black"
    />
    <path
      d="m11.413 5.019 2.585-2.585-2.585-2.586-2.585 2.586 2.585 2.585Zm.858.857 2.585 2.585 2.585-2.585-2.585-2.585-2.585 2.585ZM6.955 9.477 4.37 12.062l2.585 2.585 2.585-2.585-2.585-2.585ZM5.076 7.6l2.585-2.585-2.585-2.585-2.585 2.585 2.585 2.585Zm-.857.857L1.634 5.871-.951 8.456l2.585 2.585 2.585-2.585Zm2.31 2.31-2.585 2.585.028.029.03.028 2.527-2.642Zm.426.408-2.527 2.641 2.527 2.418 2.527-2.418-2.527-2.641Zm.426-.408 2.527 2.642.029-.028.029-.029-2.585-2.585ZM12.344 8c0 2.4-1.945 4.344-4.344 4.344v7.312c6.437 0 11.656-5.219 11.656-11.656h-7.312ZM8 3.656c2.4 0 4.344 1.945 4.344 4.344h7.312C19.656 1.563 14.437-3.656 8-3.656v7.312ZM3.656 8C3.656 5.6 5.6 3.656 8 3.656v-7.312C1.563-3.656-3.656 1.563-3.656 8h7.312ZM8 12.344A4.344 4.344 0 0 1 3.656 8h-7.312c0 6.437 5.219 11.656 11.656 11.656v-7.312Zm.828-4.74.858.857 5.17-5.17-.858-.857-5.17 5.17Zm.712 4.458 4.458-4.458-5.17-5.17L4.37 6.892l5.17 5.17ZM2.49 10.184l1.879 1.878 5.17-5.17L7.66 5.014l-5.17 5.17Zm4.313.857.857-.857-5.17-5.17-.857.857 5.17 5.17Zm2.31-2.86-2.31-2.31-5.17 5.17 2.31 2.31 5.17-5.17Zm.368.35-.426-.407-5.054 5.284.426.407 5.054-5.283Zm-4.628-.407-.426.408 5.054 5.283.426-.407-5.054-5.284Zm4.832-4.833-4.89 4.89 5.17 5.17 4.89-4.89-5.17-5.17Z"
      mask="url(#a)"
    />
  </svg>
)

export default SvgComponent
